<template>
    <div class="area_body">
        <div class="dashboard">
            <div class="recovery_page" :class="{'no_cover' : !recoveryPage.has_cover && recoveryPage.has_logo}">
                <img :src="recoveryPage.cover ? recoveryPage.cover : require('@/assets/images/thumb/default-cover.svg')" alt="" class="banner" v-if="recoveryPage.has_cover">
                <div class="content_body">
                    <img :src="recoveryPage.logo ? recoveryPage.logo : require('@/assets/images/thumb/default-cover-square.svg')" alt="" class="icon" v-if="recoveryPage.has_logo">
                    <h3>{{ recoveryPage.headline }}</h3>
                    <h5>{{ recoveryPage.sub_headline }}</h5>
                    <div class="setting_wpr mt-2">
                        <Form @submit="handleSubmit" v-slot="{ errors }" class="setting-form">
                            <div class="form_grp mt-2">
                                <div class="group_item">
                                    <label class="input_label">First Name</label>
                                    <div class="field_wpr" :class="{'has-error': errors.first_name}">
                                        <Field autocomplete="off" type="text" name="first_name" v-model="form.first_name" label="first name" placeholder="ex: Robert" rules="required" />
                                    </div>
                                    <ErrorMessage name="first_name" class="text-danger" />
                                </div>
                                <div class="group_item">
                                    <label class="input_label">Last Name</label>
                                    <div class="field_wpr" :class="{'has-error': errors.last_name}">
                                        <Field autocomplete="off" type="text" name="last_name" v-model="form.last_name" label="last name" placeholder="ex: Bacins" rules="required" />
                                    </div>
                                    <ErrorMessage name="last_name" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mt-2">
                                <div class="group_item">
                                    <label class="input_label">E-mail</label>
                                    <div class="field_wpr has_prefix" :class="{'has-error': errors.email}">
                                        <Field autocomplete="off" type="email" name="email" v-model="form.email" placeholder="hello.robert@gmail.com" rules="required|email" />
                                        <span class="prefix"><i class="fas fa-envelope"></i></span>
                                    </div>
                                    <ErrorMessage name="email" class="text-danger" />
                                </div>
                            </div>
                            <button type="button pointer" :disabled="loader" :style="`background: ${recoveryPage.button_color}; color: ${recoveryPage.button_text_color}`" class="primary_btn">
                                <i class="fa fa-spin fa-spinner" v-if="loader"></i>&nbsp;{{ loader ? 'Resubscribing' : recoveryPage.button_text }}
                            </button>
                        </Form>
                        <p class="privacy" v-if="recoveryPage.is_security">{{ recoveryPage.privacy_text }} <a :href="recoveryPage.privacy_url">Privacy Policy</a></p>
                        <p class="copyright">© Thrive Coach 2022. All rights reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Recovery Page Public Dashboard',

        data () {
            return {
                form: {
                    type: 'full',
                    first_name: '',
                    last_name: '',
                    email_name: '',
                },
                loader: false,
            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            recoveryPage (page) {
                if (page.seo_title) {
                    const title = document.getElementById('title');

                    title.text = page.seo_title;
                }

                if (page.seo_share_title) {
                    const ogTitle       = document.getElementById('og-title');
                    const twitterTitle  = document.getElementById('twitter-title');

                    ogTitle.content         = page.seo_share_title;
                    twitterTitle.content    = page.seo_share_title;
                }

                if (page.seo_share_desc) {
                    const ogDescription       = document.getElementById('og-description');
                    const twitterDescription  = document.getElementById('twitter-description');

                    ogDescription.content         = page.seo_share_desc;
                    twitterDescription.content    = page.seo_share_desc;
                }

                if (page.seo_thumb) {
                    const thumbnail       = document.getElementById('thumbnail');
                    const ogImage       = document.getElementById('og-image');
                    const twitterImage  = document.getElementById('twitter-image');
                    const twitterCard  = document.getElementById('twitter-card');

                    thumbnail.content       = page.seo_thumb;
                    ogImage.content         = page.seo_thumb;
                    twitterImage.content    = page.seo_thumb;
                    twitterCard.content     = page.seo_thumb;
                }

                if (page.seo_keyword) {
                    const keywords = document.getElementById('keywords');

                    keywords.content = page.seo_keyword;
                }

                if (page.seo_desc) {
                    const description = document.getElementById('description');
                    description.content = page.seo_desc;
                }

                if (page.seo_fav) {
                    const favicon = document.getElementById('favicon');
                    favicon.href = page.seo_fav;
                }
            },
        },

        computed: mapState({
            recoveryPage: state => state.commonModule.recoveryPagePublic,
        }),

        mounted () {
            const vm = this;

            vm.getRecoveryPagePublic(vm.$route.params);
        },

        methods: {
            ...mapActions({
                getRecoveryPagePublic: 'commonModule/getRecoveryPagePublic',
                resubscribeEmails: 'commonModule/resubscribeEmails',
            }),

            handleSubmit (form, { setFieldError, resetForm }) {
                const vm = this;
                const params = { ...vm.form, ...vm.$route.params };
                params.setFieldError = setFieldError;

                vm.loader = true;

                vm.resubscribeEmails(params).then((result) => {
                    if (result) {
                        vm.resetForm();
                        resetForm();
                        const options = Helper.swalWarningOptions('Congratulations!', 'You have now been resubscribed!');

                        Swal.fire(options)
                    }

                    vm.loader = false;
                });
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    type: 'full',
                    first_name: '',
                    last_name: '',
                    email_name: '',
                };
            },
        }
    };
</script>

<style scoped>
    .area_body .dashboard {
        height: auto;
    }

    .recovery_page {
        border: 1px solid #eaeaea;
        align-items: center;
        background: #fff;
        line-height: 0;
        width: 100%;
        flex-grow: 1;
    }

    .recovery_page .banner {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .recovery_page .content_body {
        padding: 25px 40px 40px 40px;
        max-width: 650px;
        margin: 0 auto;
    }

    .mail_brand {
        background: #e4efff;
        border-radius: 8px 8px 0 0;
        padding: 15px 20px;
    }

    .recovery_page h3 {
        font-size: 20px;
        line-height: 28px;
        color: #121525;
        font-weight: 400;
        padding-bottom: 10px;
        text-align: center;
    }

    .recovery_page .icon {
        border-radius: 50%;
        object-fit: cover;
        position: relative;
        display: block;
        border: 2px solid #fff;
        width: 180px;
        height: 180px;
        margin: -120px auto 50px auto;
    }

    .recovery_page.no_cover {
        margin-top: 100px;
    }

    .recovery_page h5 {
        font-size: 15px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
        text-align: center;
        padding-bottom: 10px;
    }

    .recovery_page .checkbox {
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 18px;
        margin: 25px 0;
    }

    .recovery_page .privacy {
        font-size: 14px;
        line-height: 18px;
        color: #666;
        font-weight: 400;
        text-align: center;
        padding: 25px 0 10px;
    }

    .recovery_page .privacy a {
        color: #2f7eed;
        text-decoration: none;
    }

    .recovery_page .copyright {
        font-size: 14px;
        line-height: 18px;
        color: #666;
        font-weight: 400;
        text-align: center;
        padding: 10px 0 0;
    }

    .setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .upload_image :deep(.tab_content) {
        height: auto;
    }

    @media(max-width: 1399px) {
        .tabs_content .setting_wpr .form_grp {
            row-gap: 30px;
        }

        .tabs_content .setting_wpr .group_item {
            min-width: 190px;
        }
    }

    .pointer {
        cursor: pointer;
    }
</style>
